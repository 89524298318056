'use strict';

import data from '/src/config/config.json';

window.app = window.app || {};

window.app = {
    config: {
        loadConfig: function() {
            if (typeof data === 'undefined') {
                console.error('Erreur lors du chargement de la configuration :', error);
            }
            window.app.config = data;
        }
    },
    utils: {
        cookies: {
            setCookie: function(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = 'expires=' + d.toUTCString();
                document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
            },
            getCookie: function(cname) {
                var name = cname + '=';
                var ca = document.cookie.split(';');
                for(var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            deleteCookie: function(cname) {
                document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            }
        },
        relativeTime: function(date_str) {
            var setting_lang_interface = app.utils.cookies.getCookie('cookieLangInterface');
            if (setting_lang_interface == 'en' || setting_lang_interface == '' || setting_lang_interface == null) {
                var seconds = ' seconds';
                var oneminute = '1 min';
                var minutes = ' min';
                var onehour = '1 hour';
                var hours = ' hours';
                var oneday = '1 day';
                var days = ' days';
                var ago = ' ago';
                var result = r + ago;
            } else if (setting_lang_interface == 'fr') {
                var seconds = ' secondes';
                var oneminute = '1 min';
                var minutes = ' min';
                var onehour = '1 heure';
                var hours = ' heures';
                var oneday = '1 jour';
                var days = ' jours';
                var ago = 'il y a ';
                var result = ago + r;
            };
            if (!date_str) {return;}
            date_str = $.trim(date_str);
            date_str = date_str.replace(/\.\d\d\d+/,"");
            date_str = date_str.replace(/-/,"/").replace(/-/,"/");
            date_str = date_str.replace(/T/," ").replace(/Z/," UTC");
            date_str = date_str.replace(/([\+\-]\d\d)\:?(\d\d)/," $1$2");
            var parsed_date = new Date(date_str);
            var relative_to = (arguments.length > 1) ? arguments[1] : new Date();
            var delta = parseInt((relative_to.getTime()-parsed_date)/1000);
            delta=(delta<2)?2:delta;
            var r = '';
            if (delta < 60) {
                r = delta + seconds;
            } else if (delta < 120) {
                r = oneminute;
            } else if (delta < (45*60)) {
                r = (parseInt(delta / 60, 10)).toString() + minutes;
            } else if (delta < (2*60*60)) {
                r = onehour;
            } else if (delta < (24*60*60)) {
                r = '' + (parseInt(delta / 3600, 10)).toString() + hours;
            } else if (delta < (48*60*60)) {
                r = oneday;
            } else {
                r = (parseInt(delta / 86400, 10)).toString() + days;
            } 
            var result;
            if (setting_lang_interface == 'en' || setting_lang_interface == '' || setting_lang_interface == null) {
                result = r + ago;
            } else if (setting_lang_interface == 'fr') {
                result = ago + r;
            }
            return result;
        }
    },
    language: {
        translation: function(lang) {
            $('.translate').each(function() {
                var transLang = $(this).attr('data-trans' + lang);
                $(this).html(transLang);
            });
        },
        initTranslation: function() {
            var setting_lang_interface = app.utils.cookies.getCookie('cookieLangInterface');
            if (setting_lang_interface == '' || setting_lang_interface == null) {
                var setting_lang_interface = 'en';
            };
            app.language.translation(setting_lang_interface);
            $('.setting_lang_interface').html(setting_lang_interface);
        },
        addListeners: function() {
            $('body').on( 'click', '.langsetting a', function(event) {
                event.preventDefault();
                var btnLang = $(this).attr('data-langsetting');
                app.language.translation(btnLang);
            });
        },
        init: function() {
            this.addListeners();
            this.initTranslation();
        }
    },
    loader: {
        init: function() {
            $("#loader_wrapper").fadeOut(1000);
        }
    },
    interface: {
        scrollToTop: function() {
            const scrollToTopButton = document.getElementById("scrollToTopBtn");
            window.addEventListener("scroll", () => {
                if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                    scrollToTopButton.style.display = "block";
                } else {
                    scrollToTopButton.style.display = "none";
                }
            });
            scrollToTopButton.addEventListener("click", () => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            });
        },
        modalSettingsInit: function(){
            var cookieModalSettings = app.utils.cookies.getCookie('cookieModalSettings');
            if (cookieModalSettings == '' || cookieModalSettings == null) {
                $('#modal_settings').show();
                $('body').addClass('open_modal');
            }
        },
        social: function(){
            var setting_lang_interface = app.utils.cookies.getCookie('cookieLangInterface');
            if (setting_lang_interface == 'fr') {
                var twitterUrl = window.app.config.twitter_fr;
                var facebookUrl = window.app.config.facebook_fr;
            } else {
                var twitterUrl = window.app.config.twitter_en;
                var facebookUrl = window.app.config.facebook_en;
            };
            $('a.social_twitter').attr('href', twitterUrl);
            $('a.social_facebook').attr('href', facebookUrl);
        },
        addListeners: function() {
            // Menu mobile
            $('.btn_menu_mobile').on( 'click', function(event) {
                event.preventDefault();
                $('.main_nav').toggleClass('active');
            });
            // Modal settings
            $('.btn_open_modal_settings').on( 'click', function(event) {
                event.preventDefault();
                $('#modal_settings').show();
                $('body').addClass('open_modal');
            });
            $('.btn_save_settings').on( 'click', function(event) {
                event.preventDefault();
                var setting_lang_interface = $('.setting_lang_interface').text();
                var setting_lang_news = $('.setting_lang_news').text();
                app.utils.cookies.setCookie('cookieModalSettings', 'true', 395);
                app.utils.cookies.setCookie('cookieLangInterface', setting_lang_interface, 395);
                app.utils.cookies.setCookie('cookieLangNews', setting_lang_news, 395);
                setTimeout(function() { window.location.reload(); }, 500);
            });
            $('.btn_close_modal').on( 'click', function(event) {
                event.preventDefault();
                $('.modal').hide();
                $('body').removeClass('open_modal');
            });
            $('.btn_no_cookies').on( 'click', function(event) {
                event.preventDefault();
                app.utils.cookies.setCookie('cookieModalSettings', 'false', 395);
                app.utils.cookies.deleteCookie('cookieLangInterface');
                app.utils.cookies.deleteCookie('cookieLangNews');
                setTimeout(function() { window.location.reload(); }, 500);
            });
            $('.btn_delete_cookies').on( 'click', function(event) {
                event.preventDefault();
                app.utils.cookies.deleteCookie('cookieModalSettings');
                app.utils.cookies.deleteCookie('cookieLangInterface');
                app.utils.cookies.deleteCookie('cookieLangNews');
                setTimeout(function() { window.location.reload(); }, 500);
            });
            // Dropdown
            $('.dropdown > .caption').on('click', function(event) {
                event.preventDefault();
                $(this).parent().toggleClass('open');
            });
            $('.dropdown > .list > .item').on('click', function(event) {
                event.preventDefault();
                $('.dropdown > .list > .item').removeClass('selected');
                $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').text( $(this).text() );
            });
            $(document).on('keyup', function(event) {
                if ( (event.keyCode || event.which) === 27 ) {
                    $('.dropdown').removeClass('open');
                }
            });
            $(document).on('click', function(event) {
                if ( $(event.target).closest(".dropdown > .caption").length === 0 ) {
                    $('.dropdown').removeClass('open');
                }
            });
        },
        init: function() {
            this.modalSettingsInit();
            this.scrollToTop();
            this.social();
            this.addListeners();
        }
    },
    news: {
        postHtml: function(postDataItems, containerSelector){
            $.each(postDataItems, function (index, value) {
                var postTitle = postDataItems[index].title;
                var postDateRaw = postDataItems[index].date;
                var postDate = app.utils.relativeTime(postDateRaw);
                var postContentRaw = postDataItems[index].content;
                var postLink = postContentRaw.match(/<div id="link"><a href="([^"]+)" rel="nofollow">([^<]+)<\/a>/)[1];
                var postAuthor = postContentRaw.match(/<div id="author">([^<]+)<\/div>/)[1];
                var postCategory = postAuthor.replace(/&#8217;|’/g, "'").toLowerCase().replace(/\s+/g, '-').replace(/[’'‘ʼ´`]/g, '');
                // var postExcerpt = postContentRaw.match(/<div id="content">([^<]+)<\/div>/)[1];

                var postImage = postContentRaw.match(/<div id="image">([^<]+)<\/div>/);
                var postThumbnailUrl = 'thumbnail-' + postCategory + ".jpg";

                if (postImage !== null) {
                    if (window.app.config.defaultIFTTTimages.includes(postImage[1])) {
                        var postImageUrl = postThumbnailUrl;
                    } else {
                        var postImageUrl = 'https://' + postImage[1];
                    }
                } else {
                    postImage = postContentRaw.match(/<div id="image"><a href="([^"]+)" rel="nofollow">([^<]+)<\/a><\/div>/);
                    if (window.app.config.defaultIFTTTimages.includes(postImage[1])) {
                        var postImageUrl = postThumbnailUrl;
                    } else {
                        var postImageUrl = postImage[1];
                    }
                }

                // var ItemHTML = '<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2"><div class="card rounded"><a href="#" class="toggle_content_modal card_link" data-postcategory="' + postCategory + '" data-postlink="' + postLink + '" data-posttitle="' + postTitle + '" data-postauthor="' + postAuthor + '" data-postexcerpt="' + postExcerpt + '" data-postimage="' + postImageUrl + '" data-postdate="' + postDateRaw + '"></a><div class="card_img_container rounded ' + postCategory + '"><img loading="lazy" decoding="async" class="img_fluid rounded card_img" src="img/thumbnail-720x405.png" alt="' + postTitle + '" style="background-image: url(' + postImageUrl + ');"></div><div class="card_author">' + postAuthor + '</div><h3 class="card_title">' + postTitle + '</h3><div class="card_date">' + postDate + '</div></div></div>';
                // $(containerSelector).append(ItemHTML);

                var ItemHTML = '<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2"><div class="card rounded"><a href="' + postLink + '" class="toggle_content_modal card_link" target="_blank"></a><div class="card_img_container rounded ' + postCategory + '"><img loading="lazy" decoding="async" class="img_fluid rounded card_img" src="thumbnail-720x405.png" alt="' + postTitle + '" style="background-image: url(' + postImageUrl + ');"></div><div class="card_author">' + postAuthor + '</div><h3 class="card_title">' + postTitle + '</h3><div class="card_date">' + postDate + '</div></div></div>';
                $(containerSelector).append(ItemHTML);
            });
        },
        getJsonNews: function(url, containerSelector) {
            $.getJSON(url, function (postData) {
                var postDataItems = postData.posts;
                app.news.postHtml(postDataItems, containerSelector);
            }).fail(function () {
                $(containerSelector + ' .news_section_error').show();
            });
        }, 
        newsSectionLoad: function(number, page, category, containerSelector) {
            var url = window.app.config.urlBaseApi + '?fields=content,title,date&number=' + number + '&page=' + page + '&category=' + category;
            var containerSelector = containerSelector;
            app.news.getJsonNews(url, containerSelector);
        },
        infiniteScrollNews: function(number, category, containerSelector) {
            let currentPage = 1;
            const postsPerPage = number;
            let isLoading = false;

            function loadingMorePosts() {
                if (isLoading) {
                    return;
                }
                isLoading = true;
                currentPage++;
                const apiUrl = window.app.config.urlBaseApi + '?fields=content,title,date&number=' + postsPerPage + '&page=' + currentPage + '&category=' + category;
                fetch(apiUrl)
                    .then((response) => response.json())
                    .then((data) => {
                    isLoading = false;
                    var postMoreDataItems = data.posts;
                    app.news.postHtml(postMoreDataItems, containerSelector);
                    if (data.length === 0) {
                        window.removeEventListener('scroll', scrollHandler);
                    }
                })
                .catch((error) => {
                    isLoading = false;
                    $(containerSelector + ' .news_section_error').show();
                });
            }
            function scrollHandler() {
                const lastArticlesContainer = document.querySelector(containerSelector);
                if (lastArticlesContainer && lastArticlesContainer.getBoundingClientRect().bottom <= window.innerHeight) {
                    loadingMorePosts();
                    // app.news.modalContent();
                }
            }
            window.addEventListener('scroll', scrollHandler);
        },
        newsSectionsInit: function() {
            var setting_lang_news = app.utils.cookies.getCookie('cookieLangNews');
            if (setting_lang_news == 'en,fr' || setting_lang_news == '' || setting_lang_news == null) {
                var setting_lang_news = 'en,fr'
                var setting_lang_news_api = 'Articles%20EN,Articles%20FR';
            } else if (setting_lang_news == 'en') {
                var setting_lang_news_api = 'Articles%20EN';
            } else if (setting_lang_news == 'fr') {
                var setting_lang_news_api = 'Articles%20FR';
            };
            $('.setting_lang_news').html(setting_lang_news);
            app.news.newsSectionLoad('12', '1', setting_lang_news_api, '#news_section_top_container');
            app.news.newsSectionLoad('12', '2', setting_lang_news_api, '#news_section_middle_container');
            app.news.newsSectionLoad('12', '3', setting_lang_news_api, '#news_section_bottom_container');
            app.news.infiniteScrollNews('36', setting_lang_news_api, '#news_section_loop_container');
            // app.news.modalContent();
        },
        featuredNews: function(type,position) {
            var setting_lang_interface = app.utils.cookies.getCookie('cookieLangInterface');
            if (setting_lang_interface == '' || setting_lang_interface == null) {
                var setting_lang_interface = 'en'
            }
            var url = window.app.config.urlBaseApi + '?fields=content,title,&number=1&page=1&category=' + type + '-' + setting_lang_interface + '-' + position;
            $.getJSON(url, function (postData) {
                var postDataItems = postData.posts[0];
                var postTitle = postDataItems.title;
                var postContentRaw = postDataItems.content;
                var postLink = postContentRaw.match(/<div id="link"><a href="([^"]+)" rel="nofollow">([^<]+)<\/a><\/div>/)[1];
                var postExcerpt = postContentRaw.match(/<div id="content">([^<]+)<\/div>/)[1];
                var postBtnLabel = postContentRaw.match(/<div id="btnlabel">([^<]+)<\/div>/)[1];
                var postAuthor = postContentRaw.match(/<div id="author">([^<]+)<\/div>/)[1];
                var postImage = postContentRaw.match(/<div id="image"><a href="([^"]+)" rel="nofollow">([^<]+)<\/a><\/div>/)[1];
                var postSponsorised = postContentRaw.match(/<div id="sponsorised">([^<]+)<\/div>/)[1];

                $('#' + position + ' .featured_post_title').html(postTitle);
                $('#' + position + ' .featured_post_excerpt').html(postExcerpt);
                $('#' + position + ' .featured_post_button_container a').attr('href', postLink);
                $('#' + position + ' .featured_post_btn').html(postBtnLabel);

                if (postSponsorised == 'true') {
                    $('#' + position + ' .featured_post_label, .featured_post_label_' + setting_lang_interface).show();
                }

                if (position == 'featured') {
                    $('body').css('background-image', 'url(' + postImage + ')');
                    var postColorCustom = postContentRaw.match(/<div id="colorcustom">([^<]+)<\/div>/)[1];
                    var cssColorCustom = '<style>:root {--main: ' + postColorCustom + ';}</style>';
                    $('head').append(cssColorCustom);
                } else {
                    $('#' + position + ' .featured_post_author').html(postAuthor);
                    $('#' + position + ' .featured_post_img').css('background-image', 'url(' + postImage + ')');
                }

            }).fail(function () {
                // console.log('Error featured article');
            });
        },
        modalContent: function() {
            setTimeout(function() {
                $('a.toggle_content_modal.card_link').on( 'click', function(event) {
                    event.preventDefault();
                    var postImage = $(this).attr('data-postimage');
                    var postAuthor = $(this).attr('data-postauthor');
                    var postCategory = $(this).attr('data-postcategory');
                    var postTitle = $(this).attr('data-posttitle');
                    var postExcerpt = $(this).attr('data-postexcerpt');
                    var postLink = $(this).attr('data-postlink');
                    var postDateRaw = $(this).attr('data-postdate');
                    var postDate = app.utils.relativeTime(postDateRaw);
                    
                    $('#modal_content .card_img').css('background-image', 'url(' + postImage + ')');
                    $('#modal_content .card_img_container').addClass(postCategory);
                    $('#modal_content .card_author').html(postAuthor);
                    $('#modal_content .card_title').html(postTitle);
                    $('#modal_content .card_excerpt').html(postExcerpt);
                    $('#modal_content .card_external_link').attr('href', postLink);
                    $('#modal_content .card_date').html(postDate);

                    $('#modal_content').show();
                    $('body').addClass('open_modal');
                });
            }, 1000);
        },
        init: function() {
            this.newsSectionsInit();
            this.featuredNews('articles','featured');
            this.featuredNews('articles','highlight-top');
            this.featuredNews('articles','highlight-middle');
            this.featuredNews('articles','highlight-bottom');
            // this.modalContent();
        }
    }
}

$(function() {
    app.config.loadConfig();

    app.language.init();
    app.interface.init();
    app.news.init();
});

$(window).on('load', function() {
    app.loader.init();
});

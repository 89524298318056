{
    "urlBaseApi": "https://public-api.wordpress.com/rest/v1.1/sites/apimakgora.wordpress.com/posts/",
    "defaultImage": "/src/config/img/thumbnail-makgora.jpg",
    "defaultIFTTTimages": [
      "https://ifttt.com/images/no_image_card.png",
      "https://ift.tt/ObK8mae",
      "https://ift.tt/GlM2K7g",
      "https://ift.tt/NExBy42",
      "https://ift.tt/xvJfSIy",
      "https://ift.tt/Ieto7sq",
      "https://ift.tt/9HAOwpL",
      "https://ift.tt/ODJLvY9",
      "https://ift.tt/VJYFeb6"
    ],
    "twitter_en":   "https://twitter.com/makgoragg",
    "twitter_fr":   "https://twitter.com/makgoragg_fr",
    "facebook_en":  "https://www.facebook.com/makgoragg",
    "facebook_fr":  "https://www.facebook.com/makgoraggFR"
  }
  